import React from 'react';

import styled from 'styled-components';

import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';
import { Select, SelectOption } from 'lib/Select';
import CheckboxRow from 'lib/CheckboxRow';
import Divider from 'lib/Divider';
import theme from 'config/theme';

import NumberInput from './NumberInput';
import FieldHeader from './FieldHeader';
import { TenantGender, TenantType } from 'models/property';

const { colors } = theme;

const Container = styled.div`
	color: ${colors.darkBlue};
	margin-top: 24px;
  padding-bottom: 32px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
`;

const SectionHeader = styled(SubTitle)`
	font-weight: 600;
	color: ${colors.darkBlue};
	font-size: 22px;
	margin-top: 16px;
	margin-bottom: 24px;
`;

const genderOptions: SelectOption[] = [
	{ value: TenantGender.All, label: 'All genders' },
	{ value: TenantGender.MaleOnly, label: 'Male only' },
	{ value: TenantGender.FemaleOnly, label: 'Females only' },
];

const tenantTypeOptions: SelectOption[] = [
	{ value: TenantType.Undergrad, label: 'Undergrad students' },
	{ value: TenantType.Postgrad, label: 'Postgrad students' },
	{ value: TenantType.YoungProfessional, label: 'Young professionals' },
	{ value: TenantType.DigitalNomad, label: 'Digital nomads' },
	{ value: TenantType.ExchangeStudent, label: 'Exchange students' },
	{ value: TenantType.Expat, label: 'Expats' },
];

interface OccupantsProps {
	currentOccupants?: number;
	availablePlaces?: number;
	totalOccupants?: number;
	currentTenantGender?: TenantGender | null;
	preferredNewTenantGender?: TenantGender | null;
	preferredTenantTypes?: TenantType[];
	onCurrentOccupantsChange(value: number): void;
	onAvailablePlacesChange(value: number): void;
	onTotalOccupantsChange(value: number): void;
	onCurrentTenantGenderChange(value: TenantGender): void;
	onPreferredNewTenantGenderChange(value: TenantGender): void;
	onPreferredTenantTypesChange(value: TenantType[]): void;
}

const Occupants = ({
	currentOccupants,
	availablePlaces,
	totalOccupants,
	currentTenantGender,
	preferredNewTenantGender,
	preferredTenantTypes,
	onCurrentOccupantsChange,
	onAvailablePlacesChange,
	onTotalOccupantsChange,
	onCurrentTenantGenderChange,
	onPreferredNewTenantGenderChange,
	onPreferredTenantTypesChange,
}: OccupantsProps) => {

  const handleCurrentOccupantsChange = (value: number | string) => {
    if (value === '') {
      onCurrentOccupantsChange(value as any);
      onTotalOccupantsChange(+(availablePlaces || 0));
      return;
    }
    onCurrentOccupantsChange(+value);
    if (+(availablePlaces || 0)) {
      onTotalOccupantsChange(+value + +(availablePlaces || 0));
      return;
    }
    onTotalOccupantsChange(+value || 0);
  }

  const handleAvailablePlacesChange = (value: number | string) => {
    if (value === '') {
      onAvailablePlacesChange(value as any);
      onTotalOccupantsChange(+(currentOccupants || 0));
      return;
    }
    onAvailablePlacesChange(+value);
    if (+(currentOccupants || 0)) {
      onTotalOccupantsChange(+value + +(currentOccupants || 0));
      return;
    }
    onTotalOccupantsChange(+value || 0);
  }
	return (
		<>
			<Title standardMargin>What about the property occupancy?</Title>
			<SubHeader>Tell potential tenants what its like living in your property.</SubHeader>
			<Container>
				<SectionHeader>Capacity</SectionHeader>
				<FieldHeader>How many occupants are currently living on the property?</FieldHeader>
				<NumberInput
					value={currentOccupants}
					label="Current number of occupants on property"
					onChange={handleCurrentOccupantsChange}
				/>
				<FieldHeader>How many places are currently available on the property?</FieldHeader>
				<NumberInput
					value={availablePlaces}
					label="Current number of places available"
					onChange={handleAvailablePlacesChange}
				/>
				<FieldHeader>How many occupants are allowed at full capacity on the property?</FieldHeader>
				<NumberInput
					value={totalOccupants}
					label="Max number of occupants"
					onChange={onTotalOccupantsChange}
				/>
				<Divider />
				{Boolean(+(currentOccupants || 0)) && (
					<>
						<SectionHeader>Current tenants</SectionHeader>
						<FieldHeader>Current tenant genders</FieldHeader>
						<Select
							placeholder="Select"
							options={genderOptions}
							selectedOption={genderOptions.find(o => o.value === currentTenantGender)}
							onChange={option => onCurrentTenantGenderChange(option.value)}
						/>
						<Divider />
					</>
				)}
				<SectionHeader>Suitable tenants</SectionHeader>
				<FieldHeader>Preferred new tenant genders</FieldHeader>
				<Select
					placeholder="Select"
					options={genderOptions}
					selectedOption={genderOptions.find(o => o.value === preferredNewTenantGender)}
					onChange={option => onPreferredNewTenantGenderChange(option.value)}
				/>
				<FieldHeader>Which type of tenants do you prefer?</FieldHeader>
				{tenantTypeOptions.map(item => (
					<CheckboxRow
						key={item.value}
						text={item.label}
						checked={(preferredTenantTypes || []).includes(item.value)}
						onClick={() => {
							const prev = preferredTenantTypes || [];
							if (prev.includes(item.value)) {
								onPreferredTenantTypesChange(prev.filter(val => val !== item.value));
								return;
							}
							onPreferredTenantTypesChange([...prev, item.value]);
						}}
					/>
				))}
			</Container>
		</>
	);
};

export default Occupants;
